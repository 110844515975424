import React from 'react'
import i18n from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next'
import languageDetector from 'i18next-browser-languagedetector'

import enUS from './static/en_US'
import frFR from './static/fr_FR'

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enUS,
      },
      fr: {
        translation: frFR,
      },
    },

    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
  })

export const I18nProvider = (props) => <I18nextProvider {...props} />
