import React from 'react'

import { BrowserRouter } from 'react-router-dom'

import { I18nProvider } from './i18n'
import Routes from './Routes'

const App = () => (
  <I18nProvider>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </I18nProvider>
)

export default App
