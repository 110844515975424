import React from 'react'

import { Routes, Route } from 'react-router-dom'

import Checkout from './views/Checkout'
import Success from './views/Success'
import Callback from './views/Callback'

const AppRoutes = () => (
  <>
    <Routes>
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/success" element={<Success />} />
      <Route path="/callback" element={<Callback />} />
    </Routes>
  </>
)

export default AppRoutes
