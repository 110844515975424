import styled from 'styled-components'

const SuccessAlert = styled.div`
  height: 100vh;
  background-color: white;
  color: #2ecc71;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: x-large;
`

export default SuccessAlert
