import { useCallback, useEffect, useState } from 'react'
import superagent from 'superagent'

import { API_HOST } from '../config'

const API_VERSION = '2022-11-22'

const useApi = ({ token: initialToken }) => {
  const [token, setToken] = useState(initialToken)

  useEffect(() => {
    setToken(initialToken)
  }, [initialToken])

  const createAdyenSession = useCallback(
    async (payload) => {
      const res = await superagent
        .post(`${API_HOST}/adyen/sessions`)
        .set({ Authorization: `Bearer ${token}`, 'Api-Version': API_VERSION })
        .send(payload)

      return res
    },
    [token],
  )

  const authorizeAdyenSession = useCallback(
    async (sessionId, authorizeBody) => {
      const res = await superagent
        .post(`${API_HOST}/adyen/sessions/${sessionId}/authorize`)
        .send(authorizeBody)
        .set({ Authorization: `Bearer ${token}`, 'Api-Version': API_VERSION })

      return res
    },
    [token],
  )

  const authenticateAdyenSession = useCallback(
    async (sessionId, body) => {
      const res = await superagent
        .post(`${API_HOST}/adyen/sessions/${sessionId}/authenticate`)
        .send(body)
        .set({ Authorization: `Bearer ${token}`, 'Api-Version': API_VERSION })

      return res
    },
    [token],
  )

  const getAdyenSession = useCallback(
    async (sessionId) => {
      const res = await superagent
        .get(`${API_HOST}/adyen/sessions/${sessionId}`)
        .set({ Authorization: `Bearer ${token}`, 'Api-Version': API_VERSION })

      return res
    },
    [token],
  )

  return {
    createAdyenSession,
    authorizeAdyenSession,
    authenticateAdyenSession,
    getAdyenSession,
  }
}

export default useApi
